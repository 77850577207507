import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import './TestAccounts.scss'
import { AuthService } from '@digitalworkflow/dwloginclient'

import { useAuth } from '../../context/AuthContext'
import { LocalSettings } from '../../utils/LocalSettings'
import CurrentUserProblemFinder from './CurrentUserProblemFinder'
import { PSL } from '@digitalworkflow/psl_web'

const TestAccounts = () => {
  const [errorMessage, setErrorMessage] = useState<string>('')
  const [currentSection, setCurrentSection] = useState<string>(LocalSettings.testAccountCategory)
  const [currentPassword, setCurrentPassword] = useState<string>(LocalSettings.testAccountPassword)
  const { setIsAuthenticated, setUser } = useAuth()

  const navigate = useNavigate()

  /** The user has clicked on a username */
  const handleSelectRole = (username: string) => {
    console.log('click', username)

    setErrorMessage('Logging in....')
    const authService = AuthService.instance()
    authService.authUserFromUsername(username, 'XXXADMINXXX').then((validResult) => {
      console.log('Auth Result:', validResult)
      if (validResult && !validResult.is_error) {
        const myToken = validResult.data.authToken
        console.log('Found Token:', myToken)

        LocalSettings.setPortalAuthToken(myToken)
        LocalSettings.setPortalRedirectUrl('/page/tasks')

        setIsAuthenticated(true)

        setUser({
          portals_enabled: validResult.data.user.portals_enabled,
          username: validResult.data.user.email,
          email: validResult.data.user.email
        })

        LocalSettings.setPortalUser(JSON.stringify(validResult.data.user))

        setTimeout(() => {
          navigate('/page/tasks')
        }, 100)
      } else {
        setErrorMessage((validResult as any).message)
      }
    })

    return undefined
  }

  const switchUserHtml = (
    strGroup: string,
    username: string,
    workgroups: string[],
    role: string,
    description: string
  ) => {
    if (strGroup !== currentSection) return <></>
    return (
      <>
        <div className='switchUserWrapper'>
          <table className='table switchUser'>
            <tbody>
              <tr>
                <td>
                  <b>Role:</b>
                </td>
                <td className='roleSelect' onClick={() => handleSelectRole(username)}>
                  {role} ({username})
                </td>
              </tr>
              <tr>
                <td> Description: </td>
                <td> {description} </td>
              </tr>
              <tr>
                <td> Workgroups: </td>
                <td>{workgroups.join(', ')}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </>
    )
  }

  const sectionButton = (strName: string) => {
    return (
      <button
        type='button'
        className={'btn ' + (strName === currentSection ? 'btn-primary' : 'btn-secondary')}
        onClick={() => {
          setCurrentSection(strName)
          LocalSettings.testAccountCategory = strName
        }}
      >
        {strName}
      </button>
    )
  }

  let passwordCorrect = false
  if (PSL.ProdRules() && currentPassword === '1f32e6d648f27bc4e72ee74249e2614e78a09ede') passwordCorrect = true
  if (!PSL.ProdRules() && currentPassword === 'bgis1234') passwordCorrect = true

  if (!passwordCorrect) {
    return (
      <div>
        <h6 className='ms-2'>System Access Accounts</h6>
        <CurrentUserProblemFinder />
        <div className='passwordWrapper'>
          <p>A password is required to access this section.</p>
          <input
            type='password'
            className='form-control'
            placeholder='Password'
            value={currentPassword}
            onChange={(e) => {
              setCurrentPassword(e.target.value)
              LocalSettings.testAccountPassword = e.target.value
            }}
          />
        </div>
      </div>
    )
  }

  return (
    <div>
      <h6 className='ms-2'>Smoke Test Accounts </h6>
      <h6 className='ms-2'>
        <a href='/page/release_notes'> View developer release notes </a>
      </h6>
      <CurrentUserProblemFinder />
      <div className='errorMessage'>{errorMessage}</div>
      <div className='help'>
        These roles are available for testing. They are available only in the staging environment for the purpose of
        reviewing and evaluating the Digital Workflow environment.
        <br />
        <br />
        Click on the role to automatically switch to that account.
        <br />
        <div className='testAccountRow'>
          <div className='testAccountLabel'> SLC: </div>
          <div className='btn-group'>
            {sectionButton('SLC')}
            {sectionButton('SLC AA')}
            {sectionButton('SLC CS FULL')}
          </div>
        </div>
        <div className='testAccountRow'>
          <div className='testAccountLabel'> Surveys: </div>
          <div className='btn-group'>
            {sectionButton('Survey')}
            {sectionButton('ARCW Survey')}
            {sectionButton('HONI Survey')}
          </div>
        </div>
        <div className='testAccountRow'>
          <div className='testAccountLabel'> Other: </div>
          <div className='btn-group'>
            {sectionButton('PSIF')}
            {sectionButton('Property Update')}
            {sectionButton('Quote Tracker')}
            {sectionButton('Quote Tracker GPE')}
          </div>
        </div>
        <div className='testAccountRow'>
          <div className='testAccountLabel'> SBP: </div>
          <div className='btn-group'>
            {sectionButton('SB RCC')}
            {sectionButton('SB Building OPS')}
          </div>
        </div>
      </div>
      {switchUserHtml(
        'PSIF',
        'psif1@bgis.com',
        ['BGIS :: PSIF :: Users'],
        'Submit Only User',
        'User that can only submit PSIF requests.'
      )}
      {switchUserHtml(
        'PSIF',
        'psif2@bgis.com',
        ['BGIS :: PSIF :: Users'],
        'Submit Only User',
        'User that can only submit PSIF requests.'
      )}
      {switchUserHtml(
        'PSIF',
        'psifintake2@bgis.com',
        ['BGIS :: PSIF :: Intake'],
        'Intake Coordinator',
        'BGIS Employee responsible for initial Intake of PSIF requests.'
      )}
      {switchUserHtml(
        'PSIF',
        'psif2a@bgis.com',
        ['BGIS :: PSIF :: IO Move Director'],
        'IO Move Director',
        'BGIS Employee role responsible for further review on the request and assignment to a move coordinator.'
      )}
      {switchUserHtml(
        'PSIF',
        'psif2b@bgis.com',
        ['BGIS :: PSIF :: IO Move Intake'],
        'IO Move Coordinator',
        'BGIS Employee role responsible for coordination of the move project.'
      )}
      {switchUserHtml(
        'PSIF',
        'psif3a@bgis.com',
        ['BGIS :: PSIF :: IO Project Director'],
        'IO Project Director',
        'BGIS Employee role responsible for further review on the request and assignment to a project coordinator.'
      )}
      {/* {switchUserHtml(
        'PSIF',
        'psif3b@bgis.com',
        ['BGIS :: PSIF :: IO Project Coordinator'],
        'IO Project Coordinator',
        'BGIS Employee role responsible for coordination of the project within PM Web.'
      )} */}
      {switchUserHtml(
        'PSIF',
        'psif4@bgis.com',
        ['BGIS :: PSIF :: IO Facility Administrator'],
        'IO Facility Administrator',
        'BGIS Employee role responsible for small project facilitiation within RealSuite'
      )}
      {switchUserHtml(
        'Property Update',
        'pur1@bgis.com',
        ['BGIS :: PropUpdate :: Users'],
        'Property Update Requestor',
        'IO User responsible for submitting property update requests.'
      )}
      {switchUserHtml(
        'Property Update',
        'purapprover@bgis.com',
        ['BGIS :: PropUpdate :: Approver'],
        'Reviewing the Incoming Request',
        'The BGIS Approver role is reviewing the incoming request.'
      )}
      {switchUserHtml(
        'Property Update',
        'purfmo1@bgis.com',
        ['BGIS :: PropUpdate :: FMO Review'],
        'FMO Review Request',
        'The BGIS FMO role responsible for further review on the request.'
      )}
      {switchUserHtml(
        'SLC',
        'slcspoc1@bgis.com',
        ['BGIS :: SLC :: Users :: SPOC'],
        'SLC SPOC / Requestor',
        'BGIS User responsible for submitting SLC - Change in service request'
      )}
      {switchUserHtml(
        'SLC',
        'slcclientapprover@bgis.com',
        ['BGIS :: SLC :: Client :: Approvers'],
        'SLC Client Approver',
        'IO Client responsible for further review on the request and approve it.'
      )}

      {switchUserHtml(
        'SLC',
        'slciodirectornorsouthapp1@bgis.com',
        ['BGIS :: SLC :: Client :: Approvers'],
        'SLC IO Director North-SouthWest Approver',
        'IO Client responsible for further review on the request and approve it.'
      )}

      {switchUserHtml(
        'Quote Tracker',
        'qtfinance1@bgis.com',
        ['BGIS UK :: Quote Tracker :: Users :: Finance Team'],
        'BGIS UK Finance Team',
        'BGIS UK Finance Team responsible for import quote details'
      )}

      {switchUserHtml(
        'Quote Tracker',
        'closebrotherfmztest1@bgis.com',
        ['BGIS UK :: Quote Tracker :: 133'],
        'BGIS UK Close Brothers Client Approver 1',
        'BGIS UK Close Brothers Client Approver responsible for further review of the astea quote details'
      )}

      {switchUserHtml(
        'Quote Tracker',
        'closebrotherfmztest2@bgis.com',
        ['BGIS UK :: Quote Tracker :: 133'],
        'BGIS UK Close Brothers Client Approver 2',
        'BGIS UK Close Brothers Client Approver responsible for further review of the astea quote details'
      )}

      {switchUserHtml(
        'Quote Tracker',
        'closebrotherfmztest3@bgis.com',
        ['BGIS UK :: Quote Tracker :: 133'],
        'BGIS UK Close Brothers Client Approver 3',
        'BGIS UK Close Brothers Client Approver responsible for further review of the astea quote details'
      )}

      {switchUserHtml(
        'Quote Tracker',
        'qtclientapprover1@bgis.com',
        ['BGIS UK :: Quote Tracker :: CloseBrothers Client'],
        'BGIS UK Close Brothers Client Approver 3 - Old',
        'BGIS UK Close Brothers Client Approver responsible for further review of the astea quote details'
      )}

      {['gpe1', 'gpe2', 'gpe3', 'gpe4', 'gpe5', 'gpe6', 'gpe7', 'gpe8', 'gpe9', 'gpe10'].map((name) => {
        return (
          <>
            {switchUserHtml(
              'Quote Tracker GPE',
              `${name}fmztest1@bgis.com`.toLowerCase(),
              [`BGIS UK :: Quote Tracker :: 156 :: ${name.toUpperCase()}`],
              `BGIS UK ${name.toUpperCase()} Client Approver 1`,
              `BGIS UK GPE Client Approver for FMZ "${name}" #1`
            )}

            {switchUserHtml(
              'Quote Tracker GPE',
              `${name}fmztest2@bgis.com`.toLowerCase(),
              [`BGIS UK :: Quote Tracker :: 156 :: ${name.toUpperCase()}`],
              `BGIS UK ${name.toUpperCase()} Client Approver 2`,
              `BGIS UK GPE Client Approver for FMZ "${name}" #2`
            )}

            {switchUserHtml(
              'Quote Tracker GPE',
              `${name}fmztest3@bgis.com`.toLowerCase(),
              [`BGIS UK :: Quote Tracker :: 156 :: ${name.toUpperCase()}`],
              `BGIS UK ${name.toUpperCase()} Client Approver 3`,
              `BGIS UK GPE Client Approver for FMZ "${name}" #3`
            )}
          </>
        )
      })}

      {switchUserHtml(
        'ARCW Survey',
        'arcw1@bgis.com',
        ['BGIS :: Survey :: ARCW'],
        'BGIS Team',
        'BGIS team responsible to send survey feedback form to client'
      )}
      {switchUserHtml(
        'ARCW Survey',
        'arcwclient1@bgis.com',
        ['BGIS :: Survey :: ARCW :: Client'],
        'Client Team',
        'Client team responsible to fill feedback form'
      )}
      {switchUserHtml(
        'SLC AA',
        'slcaaspoc1@bgis.com',
        ['BGIS :: SLC Asset Addition :: Users :: SPOC'],
        'SLC AA SPOC / Requestor',
        'BGIS User responsible for submitting SLC - Asset Addition request'
      )}
      {switchUserHtml(
        'SLC AA',
        'slcaadgt1@bgis.com',
        ['BGIS :: SLC Asset Addition :: DGT'],
        'SLC AA DGT',
        'DGT User responsible for further review on the request and assignment to Finance team.'
      )}
      {switchUserHtml(
        'SLC AA',
        'slcaafinance1@bgis.com',
        ['BGIS :: SLC Asset Addition :: Finance'],
        'SLC AA Accounting Review',
        'Finance User responsible for further review on the request and assignment to P2P team.'
      )}
      {switchUserHtml(
        'SLC AA',
        'slcaap2p1@bgis.com',
        ['BGIS :: SLC Asset Addition :: P2P'],
        'SLC AA P2P',
        'P2P User responsible for further review on the request.'
      )}

      {switchUserHtml(
        'SLC AA',
        'slcaasourcing1@bgis.com',
        ['BGIS :: SLC Asset Addition :: Sourcing'],
        'Sourcing Team',
        'Sourcing Approver will approve the request.'
      )}
      {switchUserHtml(
        'SLC AA',
        'slcaacmms1@bgis.com',
        ['BGIS :: SLC Asset Addition :: CMMS'],
        'CMMS Team',
        'CMMS Approver will approve the request.'
      )}
      {switchUserHtml(
        'SLC AA',
        'slcaautilityuser1@bgis.com',
        ['BGIS :: SLC Asset Addition :: Utilities'],
        'Utilities Team',
        'Utilities Approver will approve the request.'
      )}
      {switchUserHtml(
        'SLC AA',
        'slcaarealenergy1@bgis.com',
        ['BGIS :: SLC Asset Addition :: Real Energy'],
        'Real Energy Team',
        'Real Energy Approver will approve the request.'
      )}
      {switchUserHtml(
        'SLC AA',
        'slcaaleasing1@bgis.com',
        ['BGIS :: SLC Asset Addition :: Leasing'],
        'Leasing Team',
        'Leasing Approver will approve the request.'
      )}
      {switchUserHtml(
        'SLC AA',
        'slcaarcc1@bgis.com',
        ['BGIS :: SLC Asset Addition :: RCC'],
        'RCC Team',
        'RCC Approver will approve the request.'
      )}

      {switchUserHtml(
        'SLC AA',
        'slcaaits1@bgis.com',
        ['BGIS :: SLC Asset Addition :: ITS'],
        'ITS Team',
        'ITS Approver will approve the request.'
      )}

      {switchUserHtml(
        'SLC AA',
        'slcaascs1@bgis.com',
        ['BGIS :: SLC Asset Addition :: SCS'],
        'SCS Team',
        'SCS Approver will approve the request.'
      )}

      {switchUserHtml(
        'SLC AA',
        'slcaavendor1@bgis.com',
        ['BGIS :: SLC Asset Addition :: Vendor Management'],
        'Vendor Management Team ',
        'Vendor Management Approver will approve the request.'
      )}
      {switchUserHtml(
        'SLC AA',
        'slcaacapital1@bgis.com',
        ['BGIS :: SLC Asset Addition :: Capital Planning'],
        'Capital Planning Team ',
        'Capital Planning Approver will approve the request.'
      )}

      {switchUserHtml(
        'SLC AA',
        'slcaaclearsite1@bgis.com',
        ['BGIS :: SLC Asset Addition :: Clearsite'],
        'Clearsite Team',
        'Clearsite Approver will approve the request.'
      )}
      {switchUserHtml(
        'SLC AA',
        'slcaasafety1@bgis.com',
        ['BGIS :: SLC Asset Addition :: Safety team'],
        'Safety Team ',
        'Safety team Approver will approve the request.'
      )}

      {switchUserHtml(
        'SLC AA',
        'slcaagoverenance1@bgis.com',
        ['BGIS :: SLC Asset Addition :: Account Governance'],
        'Account Governance Team',
        'Account Governance Approver will approve the request.'
      )}
      {switchUserHtml(
        'SLC AA',
        'slcaageoportal1@bgis.com',
        ['BGIS :: SLC Asset Addition :: Geo Portal Team'],
        'Geo Portal Team',
        'Geo Portal Team Approver will approve the request.'
      )}

      {switchUserHtml(
        'SLC AA',
        'slcaasecurity1@bgis.com',
        ['BGIS :: SLC Asset Addition :: Security Team'],
        'Security Team ',
        'Security Team Approver will approve the request.'
      )}

      {switchUserHtml(
        'SLC AA',
        'slcaaoccupancy1@bgis.com',
        ['BGIS :: SLC Asset Addition :: Occupancy Team'],
        'Occupancy Team',
        'Occupancy Team Approver will approve the request.'
      )}

      {switchUserHtml(
        'Survey',
        'smallwork1@bgis.com',
        ['BGIS :: Survey :: Small Works'],
        'BGIS Team',
        'BGIS team responsible to send survey form to the client.'
      )}
      {switchUserHtml(
        'SLC CS FULL',
        'slcspoc1@bgis.com',
        ['BGIS :: SLC :: Users :: SPOC'],
        'SLC SPOC / Requestor',
        'BGIS User responsible for submitting SLC - Change in service request.'
      )}
      {switchUserHtml(
        'SLC CS FULL',
        'slcservicedetails1@bgis.com',
        ['BGIS :: SLC :: Service Delivery'],
        'SLC Service Delivery',
        'Service Delivery workgroup will add quotes for each services.'
      )}
      {switchUserHtml(
        'SLC CS FULL',
        'slcioonaccproc1@bgis.com',
        ['BGIS :: SLC :: IO On Account Procurement'],
        'SLC IO On Account Procurement',
        'IO On Account Procurement workgroup will add quotes for each services.'
      )}
      {switchUserHtml(
        'SLC CS FULL',
        'slcfmapprover1@bgis.com',
        ['BGIS :: SLC :: FM :: Approver'],
        'SLC FM Approver',
        'FM Approver will approve the request.'
      )}
      {switchUserHtml(
        'SLC CS FULL',
        'slcfinancialapprover1@bgis.com',
        ['BGIS :: SLC :: Finance :: Approver'],
        'SLC Finance Approver',
        'Finance Approver will approve the request.'
      )}
      {switchUserHtml(
        'SLC CS FULL',
        'slcpremapprover1@bgis.com',
        ['BGIS :: SLC :: Prem :: Approver'],
        'SLC Prem Approver',
        'Prem Approver will approve the request.'
      )}
      {switchUserHtml(
        'SLC CS FULL',
        'slcsrmanager1@bgis.com',
        ['BGIS :: SLC :: Senior Management :: Approver'],
        'SLC Senior Management Approver',
        'Senior Management Approver will approve the request.'
      )}
      {switchUserHtml(
        'SLC CS FULL',
        'slciodirectornorsouthapp1@bgis.com',
        ['BGIS :: SLC :: IO director :: North-SouthWest Approver'],
        'SLC IO director North-SouthWest Approver',
        'North-SouthWest Approver will approve the request.'
      )}
      {switchUserHtml(
        'SLC CS FULL',
        'slciodirectoreastcentapp1@bgis.com',
        ['BGIS :: SLC :: IO director :: East-Central Approver'],
        'SLC IO director East-Central Approver',
        'East-Central Approver will approve the request.'
      )}
      {switchUserHtml(
        'SLC CS FULL',
        'slcministryapprover1@bgis.com',
        ['BGIS :: SLC :: Ministry :: Approver'],
        'SLC Ministry Approver',
        'Ministry Approver will approve the request.'
      )}
      {switchUserHtml(
        'HONI Survey',
        'surveyhonihydro1@bgis.com',
        ['BGIS :: Survey :: Honi hydro'],
        'BGIS Team',
        'The BGIS team can view completed surveys.'
      )}
      {switchUserHtml(
        'SLC CS FULL',
        'slcfinanceapprover1@bgis.com',
        ['BGIS :: SLC :: Finance :: Approver'],
        'Finance Team',
        'Finance Approver will approve the request.'
      )}
      {switchUserHtml(
        'SLC CS FULL',
        'slcp2p1@bgis.com',
        ['BGIS :: SLC :: P2P :: Approver'],
        'P2P Team',
        'P2P Approver will approve the request.'
      )}
      {switchUserHtml(
        'SLC CS FULL',
        'slcsourcingapprover1@bgis.com',
        ['BGIS :: SLC :: Sourcing :: Approver'],
        'Sourcing Team',
        'Sourcing Approver will approve the request.'
      )}
      {switchUserHtml(
        'SLC CS FULL',
        'slccmmsapprover1@bgis.com',
        ['BGIS :: SLC :: CMMS :: Approver'],
        'CMMS Team',
        'CMMS Approver will approve the request.'
      )}
      {switchUserHtml(
        'SLC CS FULL',
        'slcioleasingapprover1@bgis.com',
        ['BGIS :: SLC :: IO Leasing :: Approver'],
        'IO leasing Team',
        'IO leasing Approver will approve the request.'
      )}
      {switchUserHtml(
        'SLC CS FULL',
        'slcrcc1@bgis.com',
        ['BGIS :: SLC :: RCC :: Approver'],
        'RCC Team',
        'RCC Approver will approve the request.'
      )}
      {switchUserHtml(
        'SLC CS FULL',
        'slcits1@bgis.com',
        ['BGIS :: SLC :: ITS :: Approver'],
        'Self Perform (BGIS ITS) Team',
        'Self Perform (BGIS ITS) Approver will approve the request.'
      )}
      {switchUserHtml(
        'SLC CS FULL',
        'slcscsapprover1@bgis.com',
        ['BGIS :: SLC :: SCS :: Approver'],
        'Self Perform (BGIS SCS) Team',
        'Self Perform (BGIS SCS) Approver will approve the request.'
      )}
      {switchUserHtml(
        'SLC CS FULL',
        'slcvendormanagementapprover1@bgis.com',
        ['BGIS :: SLC :: Vendor Management :: Approver'],
        'Vendor Management Team',
        'Vendor Management Approver will approve the request.'
      )}
      {switchUserHtml(
        'SLC CS FULL',
        'slciorps1@bgis.com',
        ['BGIS :: SLC :: IO-RPS'],
        'IO-RPS (Contract Governance) Team',
        'IO-RPS (Contract Governance) Approver will approve the request.'
      )}

      {switchUserHtml(
        'SB RCC',
        'sbrcc1@bgis.com',
        ['SB :: Alert Incoming :: 151 '],
        'Smart Building RCC Basic',
        'Smart Building User with permissions to manage alerts.'
      )}

      {switchUserHtml(
        'SB Building OPS',
        'sbops1@bgis.com',
        ['SB :: Alert Incoming :: 151 ', 'SB :: Buildings :: 151', 'SB :: Buildings :: 158', 'SB :: Ops'],
        'Smart Building Operations Manager',
        'Smart Building Operations Manager with permission to manage buildings'
      )}
    </div>
  )
}

export default TestAccounts
