import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import storage from 'redux-persist/lib/storage'
import { persistReducer } from 'redux-persist'
import { StepTaskListActiveFilter, StepTaskListState } from '../types'

const initialStepTaskListState: StepTaskListState = {
  activeFilter: {
    value: '',
    key: ''
  }
}

const stepTaskListSlice = createSlice({
  name: 'stepTaskList',
  initialState: initialStepTaskListState,
  reducers: {
    updateTaskListFilter: (state, action: PayloadAction<StepTaskListActiveFilter>) => {
      state.activeFilter = action.payload
    },
    clearTaskListFilter: (state) => {
      state.activeFilter = {
        value: null,
        key: ''
      }
    }
  }
})

const stepTaskListPersistConfig = {
  key: 'stepTaskList',
  storage,
  whitelist: [''] // only selectedParticipant will be persisted
}
const persistedParticipantsReducer = persistReducer(stepTaskListPersistConfig, stepTaskListSlice.reducer)

export const { updateTaskListFilter, clearTaskListFilter } = stepTaskListSlice.actions
export default persistedParticipantsReducer
