import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { StepButton } from '@digitalworkflow/dwtranslateclient'
import { RenderHelper } from '../../RenderHelper'

import './index.scss'
import { StyleHelper } from './StyleHelper'
import TaskListFilterCard from '../TaskList/TaskListFilterCard'

const HtmlToReactParser = require('html-to-react').Parser
const htmlToReactParser = new HtmlToReactParser()

/**
 * Props for [[`PageButton`]] component
 */
export interface PageButtonProps {
  /** Step data */
  step: StepButton
  /** Used for redirecting. */
  renderer: RenderHelper
  /** String used for redirecting.
   *
   * @example
   * "page/enroll"
   */
  targetAction: string
  /** Wrapper type */
  wrapper: string
}

/**
 * PageButton component.
 *
 * @remarks
 * Main action button of the page.
 *
 * @component PageButton
 * @category PageElements
 */

type ButtonStatusType = 'enabled' | 'disabled' | 'hidden'

const PageButton = ({ step, targetAction }: PageButtonProps) => {
  const navigate = useNavigate()

  const [buttonState, setButtonState] = useState<ButtonStatusType>('enabled')

  useEffect(() => {
    step.subscribeEnabled((status: ButtonStatusType) => {
      console.log('BUTTON STATUS:', status, step.text)
      setButtonState(status)
    })

    console.log('INITIAL BUTTON STATUS:', step.myStatus)
  }, [])

  /**
   * Button click handler.
   *
   * @remarks
   * Used for redirecting to targetAction.
   */
  const handleClick = async () => {
    if (step.options.checkOption('GoToWebSite')) {
      window.open(targetAction)
    } else {
      navigate(targetAction)
    }
  }

  const handleDownload = () => {
    window.open(targetAction)
  }

  const styleOpt = new StyleHelper(step.style)

  /** Render Normal Button */
  const baseButtonStyle = 'btn w-xs waves-effect me-3 floatButton'

  /** Render ModuleButton */
  if (step.options.checkOption('Module')) {
    return (
      <div className={`menu-module-button ${step.raw.style}`} onClick={handleClick}>
        <div className='module-title'>{htmlToReactParser.parse(step.text)}</div>
        <div className='module-description'>{htmlToReactParser.parse(step.option_2)}</div>
      </div>
    )
  }

  /** Render DownloadButton */
  if (step.options.checkOption('Download')) {
    return (
      <div className={`download-button ${step.raw.style}`} onClick={handleDownload}>
        <div className='download-title'>{step.text}</div>
        <div className='download-description'>{step.option_1}</div>
        <div className='download-description'>{step.option_2}</div>
        <div className='download-description'>{step.option_3}</div>
      </div>
    )
  }

  if (step.raw.step_option === 'Filter') {
    return <TaskListFilterCard step={step} />
  }
  return (
    <button
      disabled={buttonState === 'disabled'}
      type='button'
      className={baseButtonStyle + ' ' + styleOpt.getClassNames()}
      onClick={handleClick}
    >
      {step.option_2 && !styleOpt.isColorOption && <i className={step.option_2 + ' btn-label label-icon mr-2'}></i>}
      {step.text}
    </button>
  )
}

export default PageButton
