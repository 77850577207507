import { configureStore } from '@reduxjs/toolkit'
import { persistStore } from 'redux-persist'
import stepTaskListSlice from '../slices/stepTaskListSlice'
export const store = configureStore({
  reducer: {
    stepTaskList: stepTaskListSlice
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: ['persist/PERSIST']
      }
    }),
  devTools: process.env.NODE_ENV !== 'production'
})

export const persistor = persistStore(store)
