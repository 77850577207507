import React from 'react'
import { SBFloorPlan } from '@digitalworkflow/sbpcommon'
import { FormFieldInputProps } from '../useForm'

const SBFloorPlanInput = ({
  step,
  field,
  is_on_modal,
  is_disabled,
  error,
  warning,
  disabledFields,
  editValues,
  handleChangeEditValues,
  handleSetEditValues
}: FormFieldInputProps) => {
  console.log({
    step,
    field,
    is_on_modal,
    is_disabled,
    error,
    warning,
    disabledFields,
    editValues,
    handleChangeEditValues,
    handleSetEditValues
  })

  const buildingId = step.logicRef?.getValue('building_id')
  const editMode = step.logicRef?.getValue('mode') === 'edit'

  return <SBFloorPlan buildingId={buildingId} editMode={editMode} />
}
export default SBFloorPlanInput
