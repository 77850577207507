import { ITooltipParams, ITooltipComp } from 'ag-grid-enterprise'

export class TaskListTooltip implements ITooltipComp {
  eGui: any
  init(params: ITooltipParams & { color: string }) {
    const eGui = (this.eGui = document.createElement('div'))
    eGui.classList.add('custom-tooltip')
    eGui.innerHTML =
      params.location === 'header'
        ? `
            <div>${params.value}</div>
        `
        : `
            <div><b>Task #${params.data.task_id}</b></div>
            <div>${params.data.task_title}</div>
            <br>
            <br>
            Assigned To: ${params.data.auser === '' ? 'Nobody' : params.data.auser} <br>
            Workflow ID: ${params.data.workflow_name} <br>
        `
  }

  getGui() {
    return this.eGui
  }
}
