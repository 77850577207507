import './TestAccounts.scss'

import React, { useEffect } from 'react'

import { WorkflowUser } from '@digitalworkflow/dwloginclient'
import { Task, WorkflowState, WorkflowStateCollection } from '@digitalworkflow/dwtranslateclient'

type TaskDebugViewProps = {
  task: Task | undefined
}

const TaskDebugView: React.FC<TaskDebugViewProps> = ({ task }) => {
  const [showWorkflowState, setShowWorkflowState] = React.useState(false)
  const [workflowState, setWorkflowState] = React.useState<WorkflowState | undefined>(undefined)

  if (!task) return <></>

  useEffect(() => {
    WorkflowStateCollection.findByName(task.data.workflow_id).then((ws) => {
      setWorkflowState(ws)
    })
  }, [task])

  const list = (items: string[] | undefined) => {
    if (!items) return <></>
    return items.map((item) => {
      return (
        <div key={'item_' + item} className='item_list'>
          {item}
        </div>
      )
    })
  }

  const debugWorkflowState = () => {
    if (!workflowState) return <div>Loading</div>
    return (
      <div style={{ padding: 10, border: '1px solid #600060' }}>
        <h5>Workflow State: {workflowState.data.id}</h5>
        <table>
          <tbody>
            <tr>
              <td> Description: </td>
              <td>{workflowState.data.description}</td>
            </tr>
            <tr>
              <td> Code: </td>
              <td>{workflowState.data.code}</td>
            </tr>
            <tr>
              <td> Display Order: </td>
              <td>{workflowState.data.display_order}</td>
            </tr>
            <tr>
              <td> WG Can See: </td>
              <td>{list(workflowState.data.wg_cansee)}</td>
            </tr>
            <tr>
              <td> WG Can Action: </td>
              <td>{list(workflowState.data.wg_canaction)}</td>
            </tr>
            <tr>
              <td> WG Can Manage: </td>
              <td>{list(workflowState.data.wg_canmanage)}</td>
            </tr>
            <tr>
              <td> WG Can Hold: </td>
              <td>{list(workflowState.data.wg_canhold)}</td>
            </tr>
          </tbody>
        </table>
      </div>
    )
  }

  return (
    <div style={{ width: '100%', border: '1px solid #606060', marginTop: 10, padding: 10 }}>
      <h4>Debug Task: {task.id}</h4>
      <table>
        <tbody>
          <tr>
            <td>
              <h5>Task Title:</h5>
              {task.data.title}
            </td>
            <td>
              <h5>Task Status:</h5>
              {task.data.task_status}
            </td>
          </tr>
          <tr>
            <td>
              <h5> Current Task Permissions: </h5>
              <ul style={{ paddingLeft: 0, listStylePosition: 'inside' }}>
                {task.data.eff_perm?.map((perm) => {
                  return (
                    <li key={perm.id}>
                      {perm.id} ({perm.perm})
                    </li>
                  )
                })}
              </ul>
            </td>
            <td>
              <h5> Current User Workgroups: </h5>
              <ul style={{ paddingLeft: 0, listStylePosition: 'inside' }}>
                {WorkflowUser.GetCurrentUser()
                  .getWorkgroups()
                  .map((workgroup) => {
                    return <li key='{workgroup}'> {workgroup} </li>
                  })}
              </ul>
            </td>
          </tr>

          <tr>
            <td>
              <h5> Current Assigned User: </h5>
              {task.data.auser}
            </td>
            <td>
              <h5> Current User: </h5>
              {WorkflowUser.GetCurrentUser().username}
            </td>
          </tr>

          <tr>
            <td colSpan={2}>
              <h5> Current Workflow ID: </h5>
              <div style={{ cursor: 'pointer' }} onClick={() => setShowWorkflowState(!showWorkflowState)}>
                {task.data.workflow_id} {showWorkflowState ? '▼' : '▲'}
              </div>
              {showWorkflowState ? <div>{debugWorkflowState()}</div> : <></>}
            </td>
          </tr>

          <tr>
            <td>
              <h5> Task Creator: </h5>
              {task.data.create_by}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  )
}

export default TaskDebugView
