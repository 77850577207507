import './TaskList.scss'

import React, { ReactElement, useMemo } from 'react'

import { FindTaskResults, LogicEngine } from '@digitalworkflow/dwtranslateclient'

import TaskListButton from './TaskListButton'
import { getTaskListSettings } from './TaskListSettings'

export interface TaskListCardsProps {
  items: FindTaskResults[]
  logicRef?: LogicEngine | undefined
  setSubShowingTask?: (task: FindTaskResults) => void
  renderTaskBadges: (task: FindTaskResults) => ReactElement
  categories: (string | undefined)[]
  setContextTask: (item: FindTaskResults) => void
  setContextMenuPosition: (a: { x: number; y: number }) => void
  optionShowRejected: boolean
}

const TaskListCards = ({
  items,
  setSubShowingTask,
  renderTaskBadges,
  categories,
  setContextMenuPosition,
  optionShowRejected,
  setContextTask,
  logicRef
}: TaskListCardsProps) => {
  const generateButtonList = (categoryName: string) => {
    let tasks = items
      .filter((task) => task.dash_category === categoryName)
      .filter((task) => task.create_dt.value !== undefined)
      .filter((task) => typeof task.create_dt.value !== 'string')

    tasks = tasks.sort((a, b) => new Date(b.create_dt.value).getTime() - new Date(a.create_dt.value).getTime())

    if (tasks.length === 0) return ''

    return (
      <div key={'tl' + categoryName} className={getTaskListSettings(categoryName).style}>
        <div className='task_category'>
          {categoryName}
          <span className='badge badge-pill'>{tasks.length}</span>
        </div>
        <div className='task_button_list'>
          {tasks.map((task, index) => {
            return (
              <TaskListButton
                key={'task' + index}
                item={task}
                logicRef={logicRef}
                setContextTask={setContextTask}
                setContextMenuPosition={setContextMenuPosition}
                renderTaskBadges={renderTaskBadges}
                setSubShowingTask={setSubShowingTask}
              />
            )
          })}
        </div>
      </div>
    )
  }

  const categoriesList = useMemo(() => {
    let _categories = [...categories]
    if (!optionShowRejected) {
      _categories = _categories.filter((item) => item !== 'Rejected' && item !== 'Cancelled')
    }

    return _categories
  }, [categories, optionShowRejected])

  return (
    <div className='task-list-cards'>
      {categoriesList.map((category) => {
        return generateButtonList(category || '')
      })}
    </div>
  )
}

export default TaskListCards
