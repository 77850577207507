import { MenuItem } from '@digitalworkflow/dwreactcommon'
import React, { createContext, useContext, useState, useEffect, useMemo } from 'react'

interface MenuContextType {
  menuItems: MenuItem[]
  updateMenuItems: (newMenuItems: MenuItem[]) => void
  currentRoute: string
  updateCurrentRoute: (newRoute: string) => void
}

const MenuContext = createContext<MenuContextType | undefined>(undefined)

export const MenuProvider: React.FC<{ children: React.ReactNode; initialMenuItems: MenuItem[] }> = ({
  children,
  initialMenuItems
}) => {
  const [menuItems, setMenuItems] = useState<MenuItem[]>([])
  const [currentRoute, setCurrentRoute] = useState<string>('')

  useEffect(() => {
    if (menuItems.length === 0) {
      setMenuItems(initialMenuItems)
    }
  }, [])

  const updateMenuItems = (newMenuItems: MenuItem[]) => {
    setMenuItems(newMenuItems)
  }

  const updateCurrentRoute = (newRoute: string) => {
    setCurrentRoute(newRoute)
  }

  const contextValue = useMemo(
    () => ({
      menuItems,
      updateMenuItems,
      currentRoute,
      updateCurrentRoute
    }),
    [menuItems, currentRoute]
  )

  return <MenuContext.Provider value={contextValue}>{children}</MenuContext.Provider>
}

export const useMenu = () => {
  const context = useContext(MenuContext)
  if (context === undefined) {
    throw new Error('useMenu must be used within a MenuProvider')
  }
  return context
}
