import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { StepTable } from '@digitalworkflow/dwtranslateclient'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import './pagetable.scss'

const HtmlToReactParser = require('html-to-react').Parser
const htmlToReactParser = new HtmlToReactParser()

/**
 * Props for [[`PageTable`]] component
 */
export interface PageTableProps {
  /** Step data. */
  step: StepTable
}

/**
 * PageTable component
 *
 * @component PageTable
 * @category PageElements
 */
const PageTable = ({ step }: PageTableProps) => {
  const [tableState, setTableState] = useState({
    sortKey: '',
    sortKeyIndex: -1,
    sortDirection: '',
    tableData: step.getFormattedData()
  })

  // /* Return a valid column alignment if one is passed in */
  // const getAlign = (
  //   rawAlign: string
  // ): 'left' | 'right' | 'center' | 'justify' => {
  //   if (rawAlign && rawAlign.toLowerCase() === 'left') return 'left'
  //   if (rawAlign && rawAlign.toLowerCase() === 'right') return 'right'
  //   if (rawAlign && rawAlign.toLowerCase() === 'center') return 'center'
  //   if (rawAlign && rawAlign.toLowerCase() === 'justify') return 'justify'
  //   return 'left'
  // }

  /**
   * Used to sort the table data.
   *
   * @param sortKey - string
   * @param sortDirection - string
   * @returns void
   */
  const getSortedArray = (index: number, sortDirection: string) => {
    if (sortDirection === '') return step.getFormattedData()
    else if (sortDirection === 'desc') {
      return step?.getFormattedData().sort(function (_a: any, _b: any) {
        if (_a[index].sortValue < _b[index].sortValue) {
          return 1
        } else if (_a[index].sortValue > _b[index].sortValue) {
          return -1
        }
        return 0
      })
    } else if (sortDirection === 'asc') {
      return step?.getFormattedData().sort(function (_a: any, _b: any) {
        if (_a[index].sortValue > _b[index].sortValue) {
          return 1
        } else if (_a[index].sortValue < _b[index].sortValue) {
          return -1
        }
        return 0
      })
    }
    return step.getFormattedData()
  }

  /**
   * Used to update the table data by column sort.
   *
   * @param col - StepTable.Column.
   * @returns void
   */
  const handleSortBy = (col: StepTable.Column, index: number) => {
    if (col.is_action) {
      return
    }

    if (tableState.sortKey !== col.text) {
      setTableState({
        sortKey: col.text,
        sortKeyIndex: index,
        sortDirection: 'asc',
        tableData: getSortedArray(index, 'asc')
      })
    } else {
      if (tableState.sortDirection === 'asc') {
        setTableState({
          sortKey: col.text,
          sortKeyIndex: index,
          sortDirection: 'desc',
          tableData: getSortedArray(index, 'desc')
        })
      } else if (tableState.sortDirection === 'desc') {
        setTableState({
          sortKey: col.text,
          sortDirection: '',
          sortKeyIndex: -1,
          tableData: getSortedArray(index, '')
        })
      } else {
        setTableState({
          sortKey: col.text,
          sortKeyIndex: index,
          sortDirection: 'asc',
          tableData: getSortedArray(index, 'asc')
        })
      }
    }
  }

  /**
   * Used to render the sort icon for the column.
   *
   * @param col - StepTable.Column.
   * @returns DOM Element
   */
  const renderSortIcon = (col: StepTable.Column) => {
    if (col.text !== tableState.sortKey || tableState.sortDirection === '') {
      return <FontAwesomeIcon icon={['fal', 'sort']} />
    } else if (tableState.sortDirection === 'asc') {
      return <FontAwesomeIcon icon={['fal', 'sort-up']} />
    } else {
      return <FontAwesomeIcon icon={['fal', 'sort-down']} />
    }
  }

  const records = step.dataset.getRecords()

  console.log(records)

  return (
    <div className='table-responsive' style={{ overflowX: 'auto' }}>
      <table
        className='table fast-data-table sortable-table'
        style={{
          background: '#fff',
          width: '100%'
        }}
      >
        <thead>
          <tr className={`${tableState.sortKey}`}>
            {step.columns.map((col: StepTable.Column, index: number) => {
              const widthPercent = col.type.checkOption('width')
              return (
                <th
                  className={'align-' + col.align + ' ' + col.format + ' row-ellipse'}
                  style={{
                    fontWeight: 600,
                    cursor: 'pointer',
                    width: widthPercent ? widthPercent + '%' : ''
                  }}
                  onClick={() => handleSortBy(col, index)}
                  key={'head-col-' + col.text + index}
                >
                  {col.text}
                  <span style={{ paddingLeft: '5px' }}>{!col.is_action && renderSortIcon(col)}</span>
                </th>
              )
            })}
          </tr>
        </thead>
        <tbody>
          {tableState.tableData.map((row: any[], rowIndex: number) => {
            return (
              <tr key={'body-row-' + rowIndex}>
                {row.map((cellData: any, colIndex: number) => {
                  const cellText = cellData.text
                  return (
                    <td
                      className={
                        'align-' + step.columns[colIndex].align + ' ' + step.columns[colIndex].format + ' row-ellipse'
                      }
                      key={'body-row-' + rowIndex + '-col-' + step.columns[colIndex].text + colIndex}
                      style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}
                      id={`cell-${rowIndex}-${colIndex}`}
                    >
                      {step.columns[colIndex].is_action ? (
                        cellData.text ? (
                          <Link to={cellData.link!} className='btn btn-primary btn-sm w-xs'>
                            {cellData.text}
                            {cellData.icon && <i style={{ paddingLeft: '10px' }} className={cellData.icon} />}
                          </Link>
                        ) : (
                          ''
                        )
                      ) : step.columns[colIndex].type.checkOption('LockToggle') ? (
                        <span>LockSwitch ???</span>
                      ) : cellText === 'undefined' || cellText === undefined || cellText === null ? (
                        ''
                      ) : (
                        htmlToReactParser.parse(cellText)
                      )}
                    </td>
                  )
                })}
              </tr>
            )
          })}
        </tbody>
      </table>
    </div>
  )
}

export default PageTable
