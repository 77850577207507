import React, { useEffect, useState } from 'react'
import { Editor } from 'react-draft-wysiwyg'

import { FormFieldInputProps, useForm } from '../useForm'
import { EditorState, convertToRaw, convertFromHTML, ContentState } from 'draft-js'
import draftToHtml from 'draftjs-to-html'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'

import './index.scss'

/**
 * Converts editorState to HTML format.
 *
 * @param editorState - Draft content state
 * @returns Converted raw draft state to HTML format
 */
const getHtml = (editorState: any) => draftToHtml(convertToRaw(editorState.getCurrentContent()))

/**
 * MemoInput component
 *
 * @component MemoInput
 * @category FormElements
 */
const MemoInput = ({
  step,
  field,
  is_on_modal,
  is_disabled,
  error,
  warning,
  disabledFields,
  editValues,
  handleChangeEditValues,
  handleSetEditValues
}: FormFieldInputProps) => {
  const { getValue, isFieldDisabled, renderValidationError, setValue, isEditFieldOnModal, getPlacholder } = useForm({
    step,
    field,
    is_on_modal,
    is_disabled,
    error,
    warning,
    disabledFields,
    editValues,
    handleChangeEditValues,
    handleSetEditValues
  })

  const [editorState, setEditorState] = useState<EditorState>()

  useEffect(() => {
    const value = isEditFieldOnModal() ? editValues[field.field] : getValue()
    const safeValue = value === 'undefined' || value === undefined || value === null ? '' : value
    const contentBlock = convertFromHTML(safeValue)
    const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks)
    setEditorState(EditorState.createWithContent(contentState))
  }, [])

  /**
   * Used to change the value of a field.
   *
   * @param e - Used to set the value.
   * @returns Void
   */
  const handleEditorStateChange = (e: EditorState) => {
    setEditorState(e)
  }

  const handleChange = () => {
    const value = getHtml(editorState)
    if (isEditFieldOnModal()) {
      handleChangeEditValues(field, value)
    } else {
      setValue(value)
    }
  }

  return (
    <div className={isFieldDisabled() ? 'flex-grow-1 disabled' : 'flex-grow-1'}>
      <Editor
        readOnly={isFieldDisabled()}
        toolbarClassName='toolbarClassName'
        wrapperClassName='memo-wrapper'
        editorClassName={'editorClassName editor' + field.field.replace('.', '_')}
        editorStyle={{
          height: 'fit-content',
          display: 'flex',
          flexWrap: 'wrap',
          overflow: 'auto',
          width: '100%',
          border: 'solid #ced4da 1px',
          wordBreak: 'break-word'
        }}
        toolbar={{
          options: ['inline', 'list', 'textAlign'],
          inline: {
            inDropdown: false,
            options: ['bold', 'italic', 'underline']
          },
          list: { inDropdown: false },
          textAlign: { inDropdown: false }
        }}
        placeholder={getPlacholder()}
        // name={isEditFieldOnModal() ? 'edit_' + field.field : field.field}
        editorState={editorState}
        // disabled={isFieldDisabled()}
        onEditorStateChange={handleEditorStateChange}
        onChange={handleChange}
      />
      {renderValidationError()}
    </div>
  )
}

export default MemoInput
