import { AuthService, UserService } from '@digitalworkflow/dwloginclient'
import {
  LogicEngine,
  MetaRefreshType,
  PageEngine,
  PageEngineDebugger,
  PageManager
} from '@digitalworkflow/dwtranslateclient'
import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { Container, Row, Spinner } from 'reactstrap'
import { ActionBar } from '../../components/ActionBar/ActionBar'
import Breadcrumbs from '../../components/Breadcrumns/Breadcrumbs'
import { RenderHelper } from '../../components/RenderHelper'
import SyncModal from '../../components/SyncModal'
import { projectName } from '../../constants'

import { Helmet } from 'react-helmet'
import { useData } from '../../context/DataContext'
import { BreadcrumbItem } from '../../types/breadcrumbs'
import { deleteDatabase, syncDatabase } from '../../utils/syncDatabase'

import { PageEngineDocumentChange } from '@digitalworkflow/dwtranslateclient/lib/PageEngine/PageEngineDebugger'
import { ActionBarHelper } from '../../components/ActionBar/ActionBarHelper'
import { useMenu } from '../../context/MenuContext'
import { getSectionWrapperClassName } from '../../helpers/pageEngineHelper'
import { LocalSettings } from '../../utils/LocalSettings'
import './index.scss'
// import Joyride, { Step } from 'react-joyride'

const authService = AuthService.instance()

UserService.setAuthServiceInstance(authService)
const userService = new UserService()

PageEngineDebugger.stepDocumentChangeCallback = (doc: PageEngineDocumentChange) => {
  console.log('Document Change', doc)
}

const Dashboard = () => {
  const { updateCurrentRoute } = useMenu()
  const location = useLocation()
  const [syncing, setSyncing] = useState(true)
  const [loading, setLoading] = useState(false)
  const [pageTitle, setPageTitle] = useState('')
  const [breadcrumbItems, setBreadcrumbItems] = useState<BreadcrumbItem[]>([])
  const [allComponents, setAllComponents] = useState<Array<JSX.Element>>([])
  const [width, setWidth] = useState(0)
  const { setLogicValues, setStepTraceItems, setFormTraceItems } = useData()
  const [myLogicEngine, setMyLogicEngine] = useState<LogicEngine | undefined>(undefined)
  const [myPageEngine, setMyPageEngine] = useState<PageEngine | undefined>(undefined)

  const [myActionBarHelper, setActionBarHelper] = useState<ActionBarHelper | undefined>(undefined)
  const actionBarRef = React.useRef<ActionBarHelper>(new ActionBarHelper())

  const [firstEffectCompleted, setFirstEffectCompleted] = useState(false)
  const [lastSyncTime, setLastSyncTime] = useState<Date | undefined>(undefined)

  /** Only allow one instance of database sync */
  const syncingRef = React.useRef<Promise<void> | undefined>(undefined)

  /** Update the logic values for Trace functions
  TODO: This is not really the best way, we should load these values
  once the trace button is pressed and we need to account for sub member values.
  */
  const setPageLogicValues = (rend_helper: RenderHelper) => {
    const json = rend_helper.logicRef?.values.toJSON()
    if (json) {
      setLogicValues(json)
    }
  }

  /** Convert the PageEngine to controls for the page */
  const renderPage = async (page: PageEngine) => {
    setStepTraceItems([])
    setFormTraceItems([])
    const rend_helper = new RenderHelper()

    // Give the action bar to the render helper so that buttons are added to
    // the correct place

    /** Create the action bar helper as a reference and then update the state copy async */
    actionBarRef.current.reset()
    setActionBarHelper(actionBarRef.current)

    rend_helper.actionBarEvents = actionBarRef.current
    rend_helper.actionBarEvents.onAddButton = (helper) => {
      console.log('Action Bar Event :', helper.buttonList.length)
      // The action bar ref version did not change, just update the state copy
      setActionBarHelper(actionBarRef.current)
    }

    setLoading(true)
    rend_helper.breadcrumbItems = breadcrumbItems
    await page.processPage(rend_helper)
    const list: JSX.Element[] = []
    const breadcrumbs: BreadcrumbItem[] = []

    rend_helper.currentTarget.forEach((e) => {
      list.push(e as JSX.Element)
    })

    setAllComponents(list)

    rend_helper.breadcrumbItems.forEach((e) => {
      breadcrumbs.push(e)
    })

    console.log('logicRef', rend_helper.logicRef)

    setPageLogicValues(rend_helper)

    setBreadcrumbItems(breadcrumbs)
    setLoading(false)
  }

  const pageLoad = async () => {
    AuthService.setProjectName(projectName)
    const page: PageEngine = await PageManager.instance().findRoute(location.pathname)

    page.evInitializePage.subscribe(async (metaData: any) => {
      if (metaData.title_en) {
        setPageTitle(metaData.title_en + ' | Digital Workflow Portal')
      }
    })

    if (page && page.isValid()) {
      await renderPage(page)
      setMyLogicEngine(page.logic)
      setMyPageEngine(page)
      setSyncing(false)

      /** This event is called by the page engine when the Page database
      record has been changed.  This is refresh this page and redraw the
      commands using a new render */
      page.evForceRefresh.subscribe((evData: MetaRefreshType) => {
        console.log('Should reload the page....', evData)
        setAllComponents([<div key='loading1'> Loading </div>])
        renderPage(page)
      })
    }
  }

  useEffect(() => {
    const init = async () => {
      /** TODO:  We need to make sure we do not attempt to deleteDatabase
      while we have a database started.  We should do something like
      LocatDatabaseManager.ShutdownIfRunning()
      Delete Database
      LocalDatabaeManager Startup
      */

      if (LocalSettings.forceDbRefresh) {
        console.log('INIT Waiting for forceDbRefresh')
        const user = JSON.parse(LocalSettings.getPortalUser() ?? '')
        await deleteDatabase()
        await userService.revertDbRefresh(user.id)
      }

      console.log('INIT Waiting for SyncDatabase for Force')
      if (!syncingRef.current) {
        console.log('INIT Creating Sync Promise')
        setLastSyncTime(new Date())
        syncingRef.current = syncDatabase(setWidth, setSyncing)
      }

      /** Note that this call to syncDatabase from atif does not have the
      page load which means we are always calling syncDatabase once here
      and then again in the next useEffects so that we were always loading twice? */
      await syncingRef.current
      setFirstEffectCompleted(true)
    }

    init()
  }, [])

  /** Primary method to sync the database **/
  useEffect(() => {
    if (!firstEffectCompleted) return

    if (lastSyncTime && new Date().getTime() - lastSyncTime.getTime() > 60 * 1000 * 10) {
      // Testing with a 10 minute limit
      syncingRef.current = undefined
    }

    /** First time through, create a promise while we sync, if this triggers too fast
      we won't start a second sync.  We'll wait on the same promise */
    if (!syncingRef.current) {
      console.log('INIT Waiting for SyncDatabase Create Promise')
      setLastSyncTime(new Date())
      syncingRef.current = syncDatabase(setWidth, setSyncing)
    }

    syncingRef.current.then(() => {
      if (!syncing) {
        pageLoad()
      }
    })
  }, [firstEffectCompleted, syncing, location.pathname])

  useEffect(() => {
    const hash = location.hash
    const typeMatch = hash.match(/#type=([^&]*)/)
    const type = typeMatch ? typeMatch[1] : null
    const newRoute = type ? `${location.pathname}#type=${type}` : location.pathname
    console.log('newRoute ', newRoute)

    updateCurrentRoute(newRoute)
  }, [location])

  console.log('allComponents: ', allComponents)

  return (
    <div className='topline'>
      <Container fluid className='mainContainer topline-inner-container'>
        <Helmet>
          <meta charSet='utf-8' />
          <title>{pageTitle}</title>
          <meta name='title' content={pageTitle} />
          <meta property='og:title' content={pageTitle} />
        </Helmet>
        <Breadcrumbs breadcrumbItems={breadcrumbItems} />
        {loading ? (
          <Spinner color='primary' />
        ) : (
          <Row className='p-0'>
            {allComponents &&
              Array.isArray(allComponents) &&
              allComponents.map((item, index) => {
                return (
                  <div key={index?.toString()} className={getSectionWrapperClassName(item?.props?.step)}>
                    {item}
                  </div>
                )
              })}
          </Row>
        )}

        {!loading && myActionBarHelper && (
          <ActionBar buttons={myActionBarHelper.buttonList} page={myPageEngine} logic={myLogicEngine}></ActionBar>
        )}
        <SyncModal width={width} isOpen={syncing} />
        <div className='endBlock'>&nbsp;</div>
      </Container>
    </div>
  )
}

export default Dashboard
