interface StepProps {
  raw: {
    step_option: string
    step_type: string
  }
}

export const getGridsClass = (step: StepProps): string => {
  if (!step?.raw || !step.raw.step_option) {
    return 'col-8' // Default to 8 grids if no specific option is provided
  }

  const { step_option } = step.raw
  const { step_type } = step.raw

  if (step_type.includes('button')) {
    return 'w-auto'
  }

  const widthMatch = step_option.match(/size=(\d+)/)
  if (!widthMatch) {
    return 'col-8' // Default to 8 grids if no width is specified
  }

  const widthValue = parseInt(widthMatch[1], 10)

  switch (widthValue) {
    case 75:
      return 'col-md-6' // 75% of 12 grids maps to 6 grids (scaled to 8 grids system)
    case 50:
      return 'col-md-4' // 50% of 12 grids maps to 4 grids (scaled to 8 grids system)
    case 25:
      return 'col-md-2' // 25% of 12 grids maps to 2 grids (scaled to 8 grids system)
    default:
      return 'col-8' // Default to 8 grids if no match
  }
}

export const getSectionWrapperClassName = (step: StepProps): string => {
  if (!step?.raw || !step.raw.step_option) {
    return 'col-12'
  }

  const { step_option } = step.raw
  const { step_type } = step.raw
  const lowercaseOption = step_option.toLowerCase()

  if (step_type.includes('button')) {
    return 'w-auto'
  }

  if (lowercaseOption.includes('notepad')) {
    return 'col-12'
  }

  const widthMatch = step_option.match(/size=(\d+)/)
  if (!widthMatch) {
    return 'col-12'
  }

  const widthValue = parseInt(widthMatch[1], 10)

  switch (widthValue) {
    case 75:
      return 'col-md-9'
    case 50:
      return 'col-md-6'
    case 25:
      return 'col-md-3'
    default:
      return 'col-12'
  }
}

export const getWidthColumns = (sectionWidth: string | undefined) => {
  return sectionWidth === '100'
    ? 12
    : sectionWidth === '75'
    ? 9
    : sectionWidth === '50'
    ? 6
    : sectionWidth === '25'
    ? 3
    : 8
}

export const getNotepadColumns = (sectionWidth: string | undefined) => {
  return sectionWidth === '100'
    ? 4
    : sectionWidth === '75'
    ? 3
    : sectionWidth === '50'
    ? 4
    : sectionWidth === '25'
    ? 4
    : 4
}
